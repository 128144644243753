.auth-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.auth-content-box{
    background: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 30px 2px white;
}

.auth-content {
    width: 90vw;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 23px;
}

.auth-button {
    width: 100% !important;
}

.auth-header{
    text-align: center;
    font-size: 39px;
    font-weight: bold;
}