.container-detail-item {
  display: flex;
  align-items: center;
  color: white;
}
.container-detail-item strong {
  width: 200px;
}
.container-detail-item p {
  margin-bottom: 0px;
}

.users-type-switcher-container, .registration-mode-switcher {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.users-type-switcher-container .white, .registration-mode-switcher .white {
  color: white;
  margin: 0 15px;
}
.users-type-switcher-container button, .registration-mode-switcher button {
  background: none;
  border: none;
  margin-bottom: 10px;
}
.users-type-switcher-container .active, .registration-mode-switcher .active {
  border-bottom: 2px solid gray;
}

.pagonation-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagonation-content p {
  color: white;
  margin-top: 40px;
  margin-bottom: 0;
}

.locations-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.locations-history-item {
  display: flex;
  color: white;
  align-items: center;
}
.locations-history-item strong {
  width: 200px;
}
.locations-history-item p {
  margin-bottom: 0px;
}

.history-item {
  padding: 30px 0px;
  border-bottom: 2px solid gray;
}

.show-events-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.events-divider {
  padding: 20px 0px;
  border-bottom: 1px solid red;
}

.users-calculator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.price-amount-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  color: white;
  font-size: 24px;
}
.price-amount-content p {
  margin-bottom: 0px;
  margin-left: 10px;
}/*# sourceMappingURL=Container.css.map */