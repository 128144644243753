.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.pagination-container span {
  margin: 0px 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}

.car-images {
  width: 100%;
  margin: 10px 0px;
}

.search-by-container {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-container-button {
  margin-left: 30px;
  color: white !important;
}

.pagination-container .active-page-number {
  color: #1ED8B9;
}

.table-header {
  border-bottom: 3px solid rgb(185, 185, 185);
}

.pagination-container span {
  color: white;
}

.actions-icon {
  width: 23px;
  cursor: pointer;
}

.table-rows:hover {
  background-color: #1ED8B9;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}
.checkbox-container label {
  margin-left: 10px;
  margin-right: 10px;
}

.border-left {
  border-left: 1px solid rgb(211, 211, 211);
}

.empty-records {
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-records p {
  text-align: center;
}

.table-action-items {
  padding-right: 20px;
}

.payed-item {
  background: greenyellow !important;
}

.view-cars-item {
  margin-top: 15px;
  height: 50px;
  border-bottom: 1px solid rgb(211, 211, 211);
}
.view-cars-item p {
  margin-bottom: 0px;
}

.unpaid-item {
  background: rgb(255, 92, 92) !important;
}

.close-icon {
  width: 25px;
  cursor: pointer;
}

.ports-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tag-items {
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px;
  margin-right: 10px;
  background: rgb(226, 226, 226);
  width: -moz-fit-content;
  width: fit-content;
}

.user-table-rows {
  padding: 0 0 0 10px;
}

.show-hide-images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.tag-text {
  padding-right: 15px;
}

.view-car-title {
  font-weight: bold;
}

.view-car-value {
  color: rgb(65, 65, 65);
}

.upload-car-data-images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.map-container {
  width: 100%;
  height: 730px;
  overflow: hidden;
}

.tracking-map {
  margin-top: -380px;
}

.iframe-container {
  width: 100%;
  height: calc(100vh - 95px);
  overflow: hidden;
}

.embedded-iframe {
  margin-top: -210px;
}

.sortable {
  cursor: pointer;
}/*# sourceMappingURL=Cars.css.map */