.header-container {
  width: 100%;
  height: 65px;
  background: rgb(241, 241, 241);
  box-shadow: 0px 0px 20px 2px white;
  margin-bottom: 30px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 90%;
  margin: auto;
}

.navbar-content {
  display: flex;
  align-items: center;
}

.navbar-content {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.navbar-content li {
  margin: 0px 10px;
}
.navbar-content li a {
  text-decoration: none;
}

.header-actions {
  display: flex;
  align-items: center;
}
.header-actions p {
  margin-bottom: 0px;
  margin-right: 3px;
}
.header-actions img {
  margin-right: 10px;
}

.table-header-items {
  font-weight: 400;
}

.page-main-content {
  width: 90%;
  margin: auto;
}

.header-logo {
  font-size: 32px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.bar-icon {
  background: none;
  border: none;
  font-size: 25px;
  display: none;
}

@media (max-width: 1024px) {
  .mobile-m-t {
    margin-top: 15px;
  }
  .header-logo {
    font-size: 19px;
  }
}
@media (max-width: 900px) {
  .navbar-container, .header-actions {
    display: none;
  }
  .bar-icon {
    display: block;
  }
}/*# sourceMappingURL=Header.css.map */